import React from "react";

const experienceContent = [
  {
    position: "MEA IT Analyst Developer",
    compnayName: "BNP Paribas",
    year: "   2022 - Present",
    // details: `  Web Developers are build a website’s core structure using coding
    // languages, while designers are more visually creative and
    // user-focused.`,
  },
  {
    position: "Scrum Master",
    compnayName: "Labur Fund (Tamkeen)",
    year: "   2021 - 2022",
    // details: `  Web Developers are build a website’s core structure using coding
    // languages, while designers are more visually creative and
    // user-focused.`,
  },
  {
    position: "Android Developer",
    compnayName: "ila Bank (Bank ABC)",
    year: "   2020 - 2021",
    // details: `  Web Developers are build a website’s core structure using coding
    // languages, while designers are more visually creative and
    // user-focused.`,
  },
  {
    position: "Web Developer (Intern)",
    compnayName: "INNSAEI Consultancy",
    year: "   2018",
    // details: `  Web Developers are build a website’s core structure using coding
    // languages, while designers are more visually creative and
    // user-focused.`,
  },
  {
    position: "Developer Internship",
    compnayName: "Bahrain National Holding",
    year: "   2017",
    // details: `  Web Developers are build a website’s core structure using coding
    // languages, while designers are more visually creative and
    // user-focused.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="list_inner">
            <div className="short">
              <div className="job">
                <h3>{val.position}</h3>
                <span>{val.compnayName}</span>
              </div>
              <div className="year">
                <span>{val.year}</span>
              </div>
            </div>
            <div className="text">
              <p>{val.details}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
