import React from "react";

const personalDetails = [
  { title: "Name", info: "Sayed Ali Alkamel" },
  { title: "Birthday", info: "January 19, 1997" },
  { title: "Mail", info: "me@sayedalialkamel.com" },
  { title: "Phone", info: "+973 3359 7776" },
  // { title: "Address", info: "20, Bardeshi, Dhaka" },
  { title: "Nationality", info: "Bahraini" },
];

const aboutContentInfo = {
  meta: "About Me",
  // title: "Google Developer Expert in Dart & Flutter - Mobile Apps Development - Software Engineer",
  subTitle: "Google Developer Expert in Dart & Flutter - Mobile Apps Development - Software Enginee",
  description: `Sayed Ali is a passionate mobile apps developer with over 6 years of experience building and publishing Mobile Apps. Sayed Ali has developed more than 10 apps in his career. One of these apps has more than 2.5 million downloads and an average rating of 4.6 out of 5 on the Play Store. Sayed Ali has spent the last two years working as a native android and IOS developer building ila bank app. Since 2018, Sayed Ali has been giving talks and workshops on Flutter and mentoring developers and entrepreneurs to utilize flutter benefits to achieve their business goals.`,
};

const PersonalInfo = () => {
  return (
    <div className="know_tm_about">
      <div className="left" data-aos="fade-right">
        <div className="left_inner">
          <div className="image">
            <img src="img/thumbs/35-44.jpg" alt="" />
            <div
              className="main"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "img/about/aboutme_1.png"
                })`,
              }}
            ></div>
          </div>
          {/* End .image */}

          <div className="details">
            <ul>
              {personalDetails.map((value, i) => (
                <li key={i}>
                  <h3>{value.title}</h3>
                  <span>{value.info}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* End .left */}

      <div className="right" data-aos="fade-right">
        <div className="know_tm_main_title">
          <span>{aboutContentInfo.meta}</span>
          <h3>{aboutContentInfo.title}</h3>
        </div>
        <div className="bigger_text">
          <p>{aboutContentInfo.subTitle}</p>
        </div>
        <div className="text">
          <p>{aboutContentInfo.description}</p>
        </div>
        <div className="know_tm_button">
          <a href="img/cv/Sayed_Ali_Alkamel_CV_14_Sep_2022.pdf" download>
            Download CV
          </a>
        </div>
      </div>
      {/* End .right */}
    </div>
  );
};

export default PersonalInfo;
