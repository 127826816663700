import React from "react";

const educationContent = [
  {
    degree: "Bachelor of Information and Communications Technology : Programming Major",
    institute: "Bahrain Polytechnic",
    year: "2015 - 2019",
    // details: `The goal of cognitive science is to understand the principles of
    // intelligence with the hope that this will lead to better
    // comprehension.`,
  },
  {
    degree: "Certificate in Academic Preparation Programme. (CAP)",
    institute: "Bahrain Polytechnic",
    year: "2014 - 2015",
    // details: ` Civil engineering is arguably the oldest engineering discipline.
    // It deals with the built environment and can be dated to the first
    // time someone placed a roof.`,
  },
  {
    degree: "Microsoft Certifed Solutions Associate: Web Applications Charter Member",
    institute: "Microsoft",
    year: "Sep - 2016",
    // details: ` Civil engineering is arguably the oldest engineering discipline.
    // It deals with the built environment and can be dated to the first
    // time someone placed a roof.`,
  },
  {
    degree: "Microsoft Certifed Solutions Associate: App Builder Charter Member",
    institute: "Microsoft",
    year: "Sep - 2016",
    // details: ` Civil engineering is arguably the oldest engineering discipline.
    // It deals with the built environment and can be dated to the first
    // time someone placed a roof.`,
  },
  {
    degree: "Microsoft Certifed Solutions Developer: Web Application (MVC)",
    institute: "Microsoft",
    year: "July - 2016",
    // details: ` Civil engineering is arguably the oldest engineering discipline.
    // It deals with the built environment and can be dated to the first
    // time someone placed a roof.`,
  },
  {
    degree: "Microsoft Specialist: Programing in HTML5 with Javascript and CSS3",
    institute: "Microsoft",
    year: "June - 2015",
    // details: ` Civil engineering is arguably the oldest engineering discipline.
    // It deals with the built environment and can be dated to the first
    // time someone placed a roof.`,
  },
  {
    degree: "Microsoft Certifed Professional",
    institute: "Microsoft",
    year: "June - 2015",
    // details: ` Civil engineering is arguably the oldest engineering discipline.
    // It deals with the built environment and can be dated to the first
    // time someone placed a roof.`,
  },
  {
    degree: "CIW Web Design Specialist",
    institute: "CIW",
    year: "2017",
    // details: ` Civil engineering is arguably the oldest engineering discipline.
    // It deals with the built environment and can be dated to the first
    // time someone placed a roof.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="list_inner">
            <div className="short">
              <div className="job">
                <h3>{val.degree}</h3>
                <span>{val.institute}</span>
              </div>
              <div className="year">
                <span>{val.year}</span>
              </div>
            </div>
            <div className="text">
              <p>{val.details}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Education;
